import { ReactChild, useEffect } from 'react';
import { useRouter } from 'next/router';

import { authServiceInstance } from '@front/services';
import LoginLayout from '@ui/layouts/LoginLayout';
import { LoginPage } from '@ui/pages';

export default function Login() {
  const route = useRouter();

  useEffect(() => {
    if (authServiceInstance.isAuthenticated()) {
      route.push('/plan');
    }
  }, [route]);

  return <LoginPage />;
}

Login.getLayout = (page: ReactChild) => {
  return <LoginLayout>{page}</LoginLayout>;
};
