import { ReactChild, useState } from 'react';
import Head from 'next/head';
// import { Header, Footer } from '../organisms';

import styles from './Layout.module.scss';

function LoginLayout({ children }: { children: ReactChild }) {
  const [isLoading] = useState<boolean>(false);

  if (isLoading) {
    // TODO: Show loading?
    return <p>Loading...</p>;
  }

  return (
    <>
      <Head>
        <title>Lácteas - Backoffice</title>
        <meta name="viewport" content="user-scalable=no, width=device-width, initial-scale=1" />
        <meta name="description" content="NextJS POC" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={`App layout ${styles['layout-container']} ${styles['show-sidebar']}`}>
        <main className={`${styles.main} col d-flex flex-column`}>
          <div className={`${styles['page-content']} container-fluid`}>{children}</div>
        </main>
      </div>
    </>
  );
}
export default LoginLayout;
